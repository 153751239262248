exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-security-tsx": () => import("./../../../src/pages/cloud-security.tsx" /* webpackChunkName: "component---src-pages-cloud-security-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cyber-security-tsx": () => import("./../../../src/pages/cyber-security.tsx" /* webpackChunkName: "component---src-pages-cyber-security-tsx" */),
  "component---src-pages-devsecops-tsx": () => import("./../../../src/pages/devsecops.tsx" /* webpackChunkName: "component---src-pages-devsecops-tsx" */),
  "component---src-pages-identity-security-tsx": () => import("./../../../src/pages/identity-security.tsx" /* webpackChunkName: "component---src-pages-identity-security-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

